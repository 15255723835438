.react-datepicker {
  background: #f9f8fa;
}

.custom-calendar {
  background: #f9f8fa;
  border: none;
}

/* .inputCalendar {
  background: #f9f8fa;
  border: none;
} */

.react-datepicker__header {
  background: #f9f8fa;
  border: none;
}

.react-datepicker__month {
  background: #f9f8fa;
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
}

/* .react-datepicker__triangle {
  display: none !important;
} */
